.particles {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.devicon-large {
  height: 60px;
  margin: 15px;
}
